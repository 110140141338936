$breakpoint: 768px;

@mixin desktop {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}
